import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import LazyLoad from "vanilla-lazyload";

// --------------------------------------------------
// 🐌 General
// --------------------------------------------------

gsap.registerPlugin(ScrollTrigger);

gsap.defaults({
  ease: 'power1.inOut',
  duration: .5
})

// create a reusable effect that swaps text
gsap.registerEffect({
  name: "swapText",
  effect: (targets, config) => {
    let tl = gsap.timeline({delay: config.delay});
    tl.to(targets, {opacity: 0, duration: config.duration / 2});
    tl.add(() => targets[0].innerText = config.text);
    tl.to(targets, {opacity: 1, duration: config.duration});
    return tl;
  },
  defaults: {duration: .6}, 
  extendTimeline: true
});

// --------------------------------------------------
// 💤 Lazy Loading
// --------------------------------------------------

const lazyLoadInstance = new LazyLoad({
  // Your custom settings go here
  elements_selector: "[data-lazy]",
  callback_loaded: (el) => {
    gsap.to(el, {
      autoAlpha: 1,
      duration: .5,
      ease: 'power1.inOut',
      onComplete: () => {
        el.dispatchEvent(new Event('lazyloaded'));
      }
    })
  }
});

// --------------------------------------------------
// 🎥 Serve correct video size relative to screen size
// --------------------------------------------------

function updateVideoSources() {
  heroVideos.forEach(multiSourceVideoElement => {
      const sources = multiSourceVideoElement.getElementsByTagName("source");
      const screenWidth = window.innerWidth;

      let selectedSource = getSourceByResolution(sources, "original"); // Default to original if no other source matches

      if (screenWidth > 2000) {
          selectedSource = getSourceByResolution(sources, "1080");
      } else if (screenWidth > 540) {
          selectedSource = getSourceByResolution(sources, "720");
      } else if (screenWidth > 400) {
          selectedSource = getSourceByResolution(sources, "540");
      } else {
          selectedSource = getSourceByResolution(sources, "360");
      }

      multiSourceVideoElement.setAttribute("data-src", selectedSource.getAttribute("data-src"));
      multiSourceVideoElement.load();
  });
}

function getSourceByResolution(sources, resolution) {
  for (const source of sources) {
    if (source.getAttribute("data-resolution") === resolution) {
        return source;
    }
  }

  // If the exact resolution isn't found, find the next numerically higher resolution source
  let nextResolution = "original";
  for (const source of sources) {
    const sourceResolution = source.getAttribute("data-resolution");
    if (!isNaN(sourceResolution) && sourceResolution > resolution && (nextResolution === "original" || sourceResolution < nextResolution)) {
        nextResolution = sourceResolution;
    }
  }

  for (const source of sources) {
    if (source.getAttribute("data-resolution") === nextResolution) {
        return source;
    }
  }

  return null;
}

let heroVideos = document.querySelectorAll('video');
if (heroVideos) {
  // Initial update based on viewport width
  updateVideoSources();
}

// --------------------------------------------------
// Auto scroll
// --------------------------------------------------

// function scrollToBottom() {
//   window.scrollTo({
//     top: document.body.scrollHeight,
//     behavior: 'smooth'
//   });
// }

// // Call the function to scroll to the bottom
// scrollToBottom();

// --------------------------------------------------
// Event header
// --------------------------------------------------

let eventHeader = document.getElementById('event-header');
if(eventHeader) {
  let eventHeaderContents = document.querySelector('.event-header__contents');
  function calculateDivHeight() {
    elemHeight = eventHeaderContents.offsetHeight;
  }
  calculateDivHeight();

  let mm = gsap.matchMedia();

  // add a media query. When it matches, the associated function will run
  mm.add("(min-width: 851px)", () => {

    /* When the user scrolls down, hide the navbar. When the user scrolls up, show the navbar */
    var prevScrollpos = 100;
    console.log(prevScrollpos);
    window.onscroll = function() {
      var currentScrollPos = window.scrollY;
      console.log(currentScrollPos);
      if (prevScrollpos > currentScrollPos) {
        eventHeader.style.top = "0";
      } else {
        eventHeader.style.top = "-" + elemHeight + "px";
      }
      prevScrollpos = currentScrollPos;
    }

  });

  // add a media query. When it matches, the associated function will run
  mm.add("(max-width: 850px)", () => {

    // Set initial state
    let isMobileEventHeaderVisible = false;

    // Get the button element
    const eventHeaderButton = document.querySelector(".event-header__hide-button");

    // Define the GSAP animation
    const toggleEventHeaderAnimation = () => {
      if (isMobileEventHeaderVisible) {
        gsap.to(eventHeader, { 
          duration: 0.3,
          top: 0
        });
        gsap.effects.swapText(eventHeaderButton, {text: "Hide info"});
      } else {
        gsap.to(eventHeader, { 
          duration: 0.3, 
          top: "-" + elemHeight + "px"
        });
        gsap.effects.swapText(eventHeaderButton, {text: "Show info"});
      }
      isMobileEventHeaderVisible = !isMobileEventHeaderVisible;
    };

    // Add click event listener to the button
    eventHeaderButton.addEventListener("click", toggleEventHeaderAnimation);

  });

  window.addEventListener("resize", calculateDivHeight);


}

// --------------------------------------------------
// Bottom Navbar color
// --------------------------------------------------

const responsiveColor = document.querySelectorAll('.responsive-color');  
if(responsiveColor) {

  const allDarks = document.querySelectorAll('.dark-background');

  // Create change effect for each portfolio navigation item

  allDarks.forEach(darkElement => {

    ScrollTrigger.create({
      trigger: darkElement,
      start: "top 96%",
      end: "bottom 96%",
      toggleClass: {
        targets: ".responsive-color", 
        className: "responsive-color--light"
      },
      // markers: true
    });

  });

}


// --------------------------------------------------
// Header Navbar color
// --------------------------------------------------

const headerResponsiveColor = document.querySelectorAll('.header-responsive-color');  
if(headerResponsiveColor) {

  const allDarks = document.querySelectorAll('.dark-background');

  // Create change effect for each portfolio navigation item

  allDarks.forEach(headerDarkElement => {

    ScrollTrigger.create({
      trigger: headerDarkElement,
      start: "top 4%",
      end: "bottom 4%",
      toggleClass: {
        targets: ".header-responsive-color", 
        className: "header-responsive-color--light"
      },
      // markers: true
    });

  });

}


// --------------------------------------------------
// 📜 Client cards
// --------------------------------------------------

const cardList = document.querySelector('.client-list');  
if(cardList) {

  const allCards = cardList.querySelectorAll('.client-name');

  // Create change effect for each portfolio navigation item

  allCards.forEach(card => {

    const cardIndex = card.getAttribute("data-image");
    const correspondingImage = document.querySelector(`.client-image[data-image="${cardIndex}"]`);

    ScrollTrigger.create({
      trigger: card,
      start: "top 50%",
      end: "bottom 50%",
      onToggle: self => {
        if(self.isActive) {
          card.classList.add("client-name--in-view");
          gsap.set(correspondingImage, {
            autoAlpha: 1,
          });
        } else {
          card.classList.remove("client-name--in-view");
          gsap.set(correspondingImage, {
            autoAlpha: 0,
            delay: 0.05
          });      }
      }
    });

  });

  // Random padding assigned to client names

  // Get all elements with class "client-name"
  var clientNameDivs = document.querySelectorAll('.client-name');

  let mm = gsap.matchMedia();

  // add a media query. When it matches, the associated function will run
  mm.add("(min-width: 851px)", () => {

    // Loop through each div and add random padding-left between 1-10vw
    clientNameDivs.forEach(function(div) {
        var randomPadding = Math.floor(Math.random() * 10) + 1; // Generate a random number between 1 and 10
        div.style.paddingLeft = randomPadding + 'vw';
    });
  });
}

// --------------------------------------------------
// Index button / list
// --------------------------------------------------

const list = document.querySelector(".index__list");
if(list) {

  // Set initial state
  let isListVisible = false;

  // Get the button element
  const button = document.querySelector(".index__button");

  // Define the GSAP animation
  const toggleAnimation = () => {
    if (isListVisible) {
      gsap.to(list, { 
        opacity: 0,
        duration: 0.5,
        display: "none" 
      });
      gsap.effects.swapText(button, {text: "Index"});
    } else {
      gsap.to(list, { 
        opacity: 1, 
        duration: 0.5, 
        display: "flex" 
      });
      gsap.effects.swapText(button, {text: "Close"});
    }
    isListVisible = !isListVisible;
  };

  // Add click event listener to the button
  button.addEventListener("click", toggleAnimation);
}

// --------------------------------------------------
// Mobile index
// --------------------------------------------------

const mobileIndex = document.querySelector(".mobile-index");
if(mobileIndex) {

  // Set initial state
  let isMobileIndexVisible = false;

  // Get the button element
  const mobileIndexButton = document.querySelector(".mobile-index-button__button");
  const body = document.querySelector('body');

  // Define the GSAP animation
  const toggleMobileIndexAnimation = () => {
    if (isMobileIndexVisible) {
      gsap.to(mobileIndex, { 
        opacity: 0,
        duration: 0.5,
        display: "none" 
      });
      gsap.effects.swapText(mobileIndexButton, {text: "Index"});
      gsap.to(body, { 
        overflow: "initial" 
      });
    } else {
      gsap.to(mobileIndex, { 
        opacity: 1, 
        duration: 0.5, 
        display: "block" 
      });
      gsap.effects.swapText(mobileIndexButton, {text: "Close"});
      gsap.to(body, { 
        overflow: "hidden" 
      });
    }
    isMobileIndexVisible = !isMobileIndexVisible;
  };

  // Add click event listener to the button
  mobileIndexButton.addEventListener("click", toggleMobileIndexAnimation);
}

// --------------------------------------------------
// Mobile menu
// --------------------------------------------------

const mobileMenu = document.querySelector(".mobile-menu");
if(mobileMenu) {

  // Set initial state
  let isMobileMenuVisible = false;

  // Get the button element
  const menuButton = document.querySelector(".mobile-menu-button__button");
  const body = document.querySelector('body');

  // Define the GSAP animation
  const toggleMenuAnimation = () => {
    if (isMobileMenuVisible) {
      gsap.to(mobileMenu, { 
        opacity: 0,
        duration: 0.5,
        display: "none" 
      });
      gsap.effects.swapText(menuButton, {text: "Menu"});
      gsap.to(body, { 
        overflow: "initial" 
      });
    } else {
      gsap.to(mobileMenu, { 
        opacity: 1, 
        duration: 0.5, 
        display: "block" 
      });
      gsap.effects.swapText(menuButton, {text: "Close"});
      gsap.to(body, { 
        overflow: "hidden" 
      });
    }
    isMobileMenuVisible = !isMobileMenuVisible;
  };

  // Add click event listener to the button
  menuButton.addEventListener("click", toggleMenuAnimation);
}

// --------------------------------------------------
// Different scroll speed
// --------------------------------------------------

  const higherLevel = document.querySelector('.higher-level');  
  if(higherLevel) {
    let mm = gsap.matchMedia();

    // add a media query. When it matches, the associated function will run
    mm.add("(min-width: 851px)", () => {

    gsap.to(higherLevel, {
      y: '-55vw', // Adjust this value to control the scroll speed
      ease: 'none', // Linear easing for constant speed
      pin: false,
      scrollTrigger: {
        trigger: higherLevel,
        start: 'top bottom', // Trigger animation when the top of the .scroll-div reaches the top of the viewport
        end: 'center bottom', // Trigger animation when the bottom of the .scroll-div reaches the bottom of the viewport
        scrub: true, // Smoothly scrub through the animation as the user scrolls
      },
    });

  }
)};